var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.credentials && _vm.credentials["access_key_id"]
        ? _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _vm.credentials["is_sandbox"]
                ? _c(
                    "v-system-bar",
                    { attrs: { dark: "", color: "warning" } },
                    [_vm._v("Sandbox mode")]
                  )
                : _c("v-system-bar", { attrs: { dark: "", color: "error" } }, [
                    _vm._v("Production mode"),
                  ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "8" } },
                    [
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("th", { attrs: { width: "200" } }, [
                                        _vm._v("Label"),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.credentials["label"]) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c("tr", [
                                      _c("th", [_vm._v("Access Key ID")]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.credentials["access_key_id"]
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "th",
                                        [
                                          _vm._v(" Secret Access Key "),
                                          _c("v-icon", {
                                            staticClass: "ml-2",
                                            attrs: { small: "" },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.secretAccessKeyShown
                                                  ? "mdi-eye-off"
                                                  : "mdi-eye"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.secretAccessKeyShown =
                                                  !_vm.secretAccessKeyShown
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.secretAccessKeyShown
                                                ? _vm.credentials[
                                                    "secret_access_key"
                                                  ]
                                                : "***********"
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]),
                                    _c("tr", [
                                      _c("th", [_vm._v("Balance")]),
                                      _c("td", [
                                        _vm._v(
                                          " $" +
                                            _vm._s(
                                              _vm.credentials[
                                                "account_balance"
                                              ]["available_balance"]
                                            ) +
                                            " "
                                        ),
                                        _vm.credentials["account_balance"][
                                          "on_hold_balance"
                                        ]
                                          ? _c("span", [
                                              _vm._v(
                                                " (on hold: " +
                                                  _vm._s(
                                                    _vm.credentials[
                                                      "account_balance"
                                                    ]["on_hold_balance"]
                                                  ) +
                                                  ") "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3158881826
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "4", "align-self": "center" },
                    },
                    [
                      _vm.credentials.is_sandbox
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "my-1",
                              attrs: {
                                outlined: "",
                                small: "",
                                width: "200",
                                height: "40",
                                color: "error",
                                loading: _vm.settingActiveSandboxMode,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveSandboxMode(false)
                                },
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-swap-horizontal")]),
                              _vm._v(" Change to"),
                              _c("br"),
                              _vm._v("production mode "),
                            ],
                            1
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "my-1",
                              attrs: {
                                outlined: "",
                                small: "",
                                width: "200",
                                height: "40",
                                color: "warning",
                                loading: _vm.settingActiveSandboxMode,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveSandboxMode(true)
                                },
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-swap-horizontal")]),
                              _vm._v(" Change to"),
                              _c("br"),
                              _vm._v("sandbox mode "),
                            ],
                            1
                          ),
                      _c("br"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-1",
                          attrs: {
                            outlined: "",
                            small: "",
                            width: "200",
                            color: "grey",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.dialogManageCredentials.show()
                            },
                          },
                        },
                        [_vm._v(" Manage credentials ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _vm._v(" MTurk account is not set. "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "indigo" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.dialogManageCredentials.show()
                        },
                      },
                    },
                    [_vm._v(" Set credentials ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("dialog-manage-credentials", {
        ref: "dialogManageCredentials",
        attrs: { client: _vm.client },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }