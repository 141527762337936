var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticClass: "text-center",
          staticStyle: { height: "300px" },
          attrs: { align: "center" },
        },
        [
          _c(
            "v-col",
            [
              _c("div", { staticClass: "text-h3 pa-6" }, [
                _vm._v("Amazon Mechanical Turk"),
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: {
                    outlined: "",
                    color: "indigo",
                    href: _vm.workerSiteUrl,
                    target: "_blank",
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-account-group"),
                  ]),
                  _vm._v(" Workers"),
                  _vm.isSandbox ? _c("span", [_vm._v("' Sandbox")]) : _vm._e(),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: {
                    outlined: "",
                    color: "indigo",
                    href: _vm.requesterSiteUrl,
                    target: "_blank",
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-account-circle"),
                  ]),
                  _vm._v(" Requesters"),
                  _vm.isSandbox ? _c("span", [_vm._v("' Sandbox")]) : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("div", { staticClass: "text-h6 mb-5" }, [
                _vm._v("Credentials"),
              ]),
              _c("credentials-card", {
                attrs: {
                  duct: _vm.duct,
                  credentials: _vm.credentials,
                  client: _vm.client,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-6", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("div", { staticClass: "text-h6 mb-5" }, [_vm._v("Services")]),
              _c("page-cards"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }